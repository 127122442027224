import React, { useState } from 'react';
import '../styles/method-payment.scss';
import { Layout, Typography, Button, Row, Col, Form, Input } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
const { Header, Content } = Layout;
const { Title, Text } = Typography;
import VisaIMg from '../images/visa.svg';

const MethodPayment = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <div>
      <Header className="site-layout-background header" style={{ padding: 0 }}>
        <Button
          className="button-back"
          size="large"
          href="/"
          icon={<ArrowLeftOutlined />}
        />
        <Text className="title-step" strong>
          Medios de Pago
        </Text>
      </Header>
      <Content>
        <div className="content-payment">
          <Row>
            <Title className="subtitle-step" level={5}>
              Medios de pago registrados, puedes registrar más con el botón +
            </Title>
          </Row>
          <div className="content-pay">
            <Row justify="space-around">
              <Col span={18}>
                <Title className="subtitle-step" level={5}>
                  BANCO PICHINCHA
                </Title>
              </Col>
              <Col span={6}>
                <img src={VisaIMg} alt="visa"></img>
              </Col>
            </Row>
            <Row justify="start">
              <Col span="3">
                <Title className="subtitle-step" level={5}>
                  ***
                </Title>
              </Col>
              <Col span="3">
                <Title className="subtitle-step" level={5}>
                  ***
                </Title>
              </Col>
              <Col span="3">
                <Title className="subtitle-step" level={5}>
                  ***
                </Title>
              </Col>
              <Col span="3">
                <Title className="subtitle-step" level={5}>
                  4567
                </Title>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={12}>
                <Text className="subtitle-step" level={5}>
                  Nombre tarjeta
                </Text>
              </Col>
              <Col span={12}>
                <Text className="subtitle-step" level={5}>
                  Fecha expiración
                </Text>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={12}>
                <Title className="subtitle-step" level={5}>
                  John Doe
                </Title>
              </Col>
              <Col span={12}>
                <Title className="subtitle-step" level={5}>
                  05 / 2021
                </Title>
              </Col>
            </Row>
          </div>
          <Row justify="center">
            <Button
              className="btn-payment"
              type="primary"
              shape="circle"
              onClick={() => {
                setShowForm(!showForm);
              }}
              icon={<PlusOutlined />}
            />
          </Row>
          {showForm && (
            <Row>
              <Form>
                <Form.Item>
                  <Col span={6}>
                    <img src={VisaIMg} alt="visa"></img>
                  </Col>
                </Form.Item>
                <Form.Item>
                  <Col span={24}>
                    <Text className="subtitle-step" level={5}>
                      Número de tarjeta
                    </Text>
                  </Col>
                </Form.Item>
                <Form.Item>
                  <Input size="large" />
                </Form.Item>
                <Form.Item>
                  <Row justify="start">
                    <Col span={13}>
                      <Text className="subtitle-step" level={5}>
                        Fecha expiración
                      </Text>
                    </Col>
                    <Col span={8}>
                      <Text className="subtitle-step" level={5}>
                        CVV
                      </Text>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row justify="space-around">
                    <Col span={12}>
                      <Input size="large" />
                    </Col>
                    <Col span={11}>
                      <Input size="large" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Col span={24}>
                    <Text className="subtitle-step" level={5}>
                      Nombre tarjeta
                    </Text>
                  </Col>
                </Form.Item>
                <Form.Item>
                  <Input size="large" />
                </Form.Item>
              </Form>
            </Row>
          )}
        </div>
      </Content>
    </div>
  );
};
export default MethodPayment;
